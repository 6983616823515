import { useRef, useState } from "react";
import { classNames } from "../helpers";

type FullScreenVideoProps = {
  title: string;
  videos: {
    title: string;
    imagePng: string;
    videoMp4: string;
    videoWebm: string;
    duration?: number;
  }[];
};

const FADE_TIME = 0.5;

export default function FullScreenVideo({
  title,
  videos,
}: FullScreenVideoProps) {
  const videoRefs = useRef<HTMLVideoElement[]>(new Array(videos.length));
  const [videoIndex, setVideoIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <div className="-z-20 bg-black w-[100%] h-[100dvh] overflow-hidden brightness-[0.5]">
        {videos.map(
          (
            { title: videoTitle, imagePng, videoMp4, videoWebm, duration },
            index
          ) => (
            <video
              ref={(el) => {
                if (el) {
                  videoRefs.current[index] = el;
                }
              }}
              muted
              preload="auto"
              key={videoTitle}
              autoPlay={index === 0}
              onTimeUpdate={(e) => {
                if (videoIndex !== index) {
                  return;
                }

                const currentTime = e.currentTarget.currentTime;
                const remainingTime =
                  (duration ?? e.currentTarget.duration) - currentTime;
                const ended =
                  remainingTime < FADE_TIME || e.currentTarget.paused;

                if (!loaded && currentTime > FADE_TIME) {
                  setLoaded(true);
                }

                const nextVideoIndex =
                  videoIndex + 1 > videos.length - 1 ? 0 : videoIndex + 1;
                const prevVideoIndex =
                  videoIndex - 1 < 0 ? videos.length - 1 : videoIndex - 1;

                const prevVideoPlaying =
                  !videoRefs.current[prevVideoIndex].paused;
                if (prevVideoPlaying && currentTime >= FADE_TIME) {
                  videoRefs.current[prevVideoIndex].pause();
                  videoRefs.current[prevVideoIndex].currentTime = 0;
                }

                const nextVideoPlaying =
                  !videoRefs.current[nextVideoIndex].paused;

                if (ended && !nextVideoPlaying) {
                  videoRefs.current[nextVideoIndex].pause();
                  videoRefs.current[nextVideoIndex].currentTime = 0;
                  videoRefs.current[nextVideoIndex].play();

                  setVideoIndex(nextVideoIndex);
                }
              }}
              className={classNames(
                "min-w-full min-h-full w-auto h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover",
                videoIndex === index ? "-z-20" : "-z-30",
                index !== 0 && !loaded ? "hidden" : "flex",
                videoIndex === index ? "animate-fade-in " : "animate-fade-out"
              )}
              style={{
                animationFillMode: "forwards",
              }}
              poster={imagePng}
            >
              <source src={videoWebm} type="video/webm; codecs=vp9,vorbis" />
              <source src={videoMp4} type="video/mp4" />
            </video>
          )
        )}
      </div>
      <div className="pt-24 absolute top-0 bottom-0 w-[100%] h-[100%] text-white flex items-center justify-center bg-transparent text-center">
        <h1
          className="bold text-6xl animate-fade-in-up-long max-w-5xl p-8"
          style={{
            textShadow: "0 2px 3px rgba(0, 0, 0, 0.3)",
          }}
        >
          {title}
        </h1>
      </div>
    </>
  );
}
