import { classNames } from "../../helpers";
import FullScreenVideo from "../FullScreenVideo";

export type PageHeaderProps = {
  title: string;
  subtitle?: React.ReactNode;
  image?: string;
  videos?: {
    title: string;
    imagePng: string;
    videoMp4: string;
    videoWebm: string;
    duration?: number;
  }[];
  headerClassName?: string;
};

export default function PageHeader({
  title,
  subtitle,
  image,
  videos,
  headerClassName,
}: PageHeaderProps) {
  if (videos && videos.length > 0) {
    return <FullScreenVideo title={title} videos={videos} />;
  }

  return (
    <header className="h-[250px] sm:h-[350px] flex flex-col justify-center items-center pt-24 relative">
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className={`bg-gray-300 -z-20 absolute top-0 left-0 w-full h-full bg-cover bg-center brightness-[0.5]`}
      ></div>
      <div className="animate-fade-in-up text-white">
        <h1
          className={classNames(
            "font-normal text-4xl sm:text-5xl",
            headerClassName
          )}
          style={{
            textShadow: "0 2px 3px rgba(0, 0, 0, 0.3)",
          }}
        >
          {title}
        </h1>
        {subtitle ?? null}
      </div>
    </header>
  );
}
